import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 2000.000000 2000.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">



<path d="M8360 13929 c-510 -65 -955 -403 -1154 -876 -42 -99 -69 -188 -93
-308 -17 -85 -18 -225 -18 -2750 l0 -2660 23 -105 c67 -305 187 -526 401 -741
64 -64 150 -140 191 -168 l75 -52 5 1113 5 1113 27 57 c34 73 103 142 176 176
l57 27 285 0 c283 0 285 0 335 -25 64 -31 144 -111 175 -175 l25 -50 3 -1222
2 -1223 774 0 c434 0 842 5 928 10 518 36 926 153 1301 373 345 203 610 489
782 842 125 256 204 561 235 905 35 398 -27 749 -194 1085 -179 361 -428 626
-778 825 l-126 72 76 58 c225 169 416 394 522 615 126 258 220 660 220 933 0
275 -75 587 -206 851 -176 357 -449 664 -789 887 -100 65 -292 174 -308 174
-4 0 -8 -442 -9 -982 l-3 -983 -34 -63 c-41 -75 -94 -125 -170 -161 l-56 -26
-280 0 -280 0 -57 27 c-73 34 -142 103 -176 176 l-27 57 -3 1103 -2 1102 -898
-1 c-493 -1 -926 -5 -962 -10z m366 -1260 c333 -59 601 -297 700 -624 25 -82
27 -105 28 -250 0 -146 -2 -168 -27 -248 -96 -311 -325 -535 -635 -619 -111
-30 -324 -30 -437 1 -448 121 -730 558 -651 1009 50 288 220 517 482 648 168
84 365 114 540 83z m3054 -4114 l0 -35 -855 0 -855 0 0 35 0 35 855 0 855 0 0
-35z m0 -245 l0 -40 -855 0 -855 0 0 40 0 40 855 0 855 0 0 -40z m0 -250 l0
-40 -855 0 -855 0 0 40 0 40 855 0 855 0 0 -40z m0 -245 l0 -35 -855 0 -855 0
0 35 0 35 855 0 855 0 0 -35z"/>
<path d="M8735 11820 c-131 -131 -252 -244 -267 -251 -59 -24 -93 -6 -224 122
l-122 120 -34 -30 -33 -30 150 -151 c96 -96 162 -154 182 -161 70 -23 80 -15
376 279 152 152 277 281 277 288 0 13 -42 54 -56 54 -5 0 -117 -108 -249 -240z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
